import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import news9pic1 from '../assets/images/news9-1.jpg'
import news9pic2 from '../assets/images/news9-2.jpg'
import news9pic3 from '../assets/images/news9-3.jpg'
import news9pic4 from '../assets/images/news9-4.jpg'
import ios from '../assets/images/app-store.svg'
import google from '../assets/images/google-play.svg'
const News9 = props => (
  <Layout>
    <Helmet>
      <title>【恢復營業，你準備好了嗎？】​</title>
      <meta name="description" content="三健客防疫" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>
              【恢復營業，
              <br />
              你準備好了嗎？】
            </h1>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news9pic1} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h3>
            拍板！7/27降級 健客已經迫不及待了是吧？ 準備好久違的踏進健身房了嗎？
            準備好找回那遺失的肌肉了嗎？
          </h3>
          <h3>三健客宣布 7/27 正式恢復場館服務！</h3>
          <p>
            為提供健客們安心的健身環境， 三健客將於 7/26
            進行場館全面消毒及大掃除， 所有人員也於每7天進行快篩/ PCR，
            並全程配戴口罩及護目鏡， 請大家安心來三健客進行尋回肌肉之旅。 但是！
            為了確保安全的前提之下，三健客決定增設以下開放條件
          </p>
          <h3>1.</h3>
          <p>
            為維護各位會籍健客權益，將不開放會籍進場制度，僅開放app購買單次入場方式
            因應疫情關係，如無法綁定信用卡付款之健客，現場將提供協助現金付款
            健客們的會籍將自動再延長，無須提出請假申請，
            我們將會按照實際暫停服務天數完整返還給健客們。 (*app購買選項如圖示2)
          </p>
          <h3>2.</h3>
          <p>
            同時入館人員僅限50人 各位可於出門前至三健客app觀看及時入館人數，
            以防現場人數已達上限而無法進場。 (查看目前入館人數方式如圖示3、4))
          </p>
          <h3>3.</h3>
          <p>不開放淋浴間、瑜珈墊區域、放鬆工具</p>
          <h3>4.</h3>
          <p>
            所有入場人員皆須全程配戴口罩，確實遮住口鼻，並主動出示實聯制簡訊、量體溫及酒精消毒。
          </p>
          <h3>5.</h3>
          <p>器材使用前後，請務必自行再次於接觸位置消毒。</p>
          <h3>6.</h3>
          <p>
            不共用器材，組間不輪替，請確認上一位使用者消毒完成後，再接續使用器材。
          </p>
          <p>
            館內將有專門人員全場監督，若無法配合以上辦法，
            三健客將有權不提供場館服務。
          </p>
          <h3>
            防疫期間 你我有責 降級不代表鬆懈 唯有遵守防疫規範才能戰勝疫情
            三健客與健客們一同努力
          </h3>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news9pic2} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={news9pic3} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={news9pic4} alt="" />
                </span>
              </div>
            </div>
          </div>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/3muscler/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/3musclers/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
				<li>
          <a
            href="https://apps.apple.com/tw/app/3musclers-%E4%B8%89%E5%81%A5%E5%AE%A2%E5%81%A5%E8%BA%AB%E4%BF%B1%E6%A8%82%E9%83%A8/id1486517436"
            style={{top:'10px'}}
				className='icon svg'
          >
            <img src={ios} alt='ios'/>
          </a>
        </li>
        <li>
          <a
            href="https://play.google.com/store/apps/details?id=com.appworkout.fitbutler.threemusclers&hl=zh_TW"
            style={{top:'10px'}}
				className='icon svg'
          >
            <img src={google} alt='google'/>
          </a>
        </li>
          </ul>
          <iframe
            src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2F&width=450&layout=standard&action=like&size=small&share=true&height=35&appId=3212226998840909"
            width="450"
            height="35"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allow="encrypted-media"
          ></iframe>
        </div>
      </section>
    </div>
  </Layout>
)

export default News9
